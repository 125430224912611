
//Pre Loader Start Here

$(window).on('load', function () {
  $('.loader-main').fadeOut();
}); 

//Pre Loader End Here  

//Loader Js

window.addEventListener("load", function () {
	const Loader = document.querySelector(".loader-main");
	Loader.classList.add("hidden");
});

//Loader Js 

// AOS Animations JS Start Here

AOS.init({
  once: true,
});
AOS.refresh();

// AOS Animations JS End here

$(document).ready(function(){
  $(".overlay-close").click(function(){
      $(".offcanvas").removeClass("show");
      $(".offcanvas-backdrop").removeClass("show");
      $(".offcanvas-backdrop").addClass("canvas-display");
      $("body").addClass("body-visible");
  });
});

$(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $('.nav-otr').addClass('top-header-fixed');
    } else {
      $('.nav-otr').removeClass('top-header-fixed');
    }
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
      $('.home-active').addClass('home');
    } else {
      $('.home-active').removeClass('home');
    }
  });
  

$(document).ready(function(){
    var sectionIds = $('a.a-items');
    $(document).scroll(function(){
            sectionIds.each(function(){
            var container = $(this).attr('href');
            var containerOffset = $(container).offset().top;
            var containerHeight = $(container).outerHeight();
            var containerBottom = containerOffset + containerHeight;
            var scrollPosition = $(document).scrollTop();
            if(scrollPosition < containerBottom - 20 && scrollPosition >= containerOffset - 20){
                $(this).addClass('active');
            } else{
                $(this).removeClass('active');
            }
        });
    });
});



//Counter up Start Here

$('.counter').counterUp({
  delay: 10,
  time: 1000
});

//Counter up End Here 





const swiper = new Swiper('#team-slider', {
  spaceBetween: 24,
  loop: false,
  slidesPerView: 4,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    575: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 4,
    },
  },
});